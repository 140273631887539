import React from "react"
import { useCourseStore } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import CourseFooterBool from "../../../../../components/course-footer-bool"
import Paragraph from "../../../../../components/paragraph"
import { getCourseNavigation } from "../../../../../store/courses"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterBool
          title="Ist das ein Gedicht?"
          to="/kurse/versteckte-ordnung/01-gedicht-erkennen/im-sueden-sommerlich/loesung"
          onSubmit={(submission) => {
            setAnswer({
              courseId: "versteckte-ordnung",
              chapterId: "01-gedicht-erkennen",
              taskId: "im-sueden-sommerlich",
              answer: submission,
            })
          }}
        />
      }
      navigation={navigation}
    >
      <Seo title="Im Süden sommerlich" />
      <Box>
        <Stack>
          <Heading as="h2" level={2}>
            Im Süden sommerlich <br />
            Wolkiger und windiger Norden
          </Heading>
          <Paragraph>
            Am Wochenende steht ein eher kühler, windiger und wolkiger
            Nordwesten einem sommerlichen Süden gegenüber. In der neuen Woche
            ist es zunächst auch im Süden kühler, anschließend geht es aber beim
            Wetter insgesamt bergauf.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
